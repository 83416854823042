import {navigate} from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';
import URL_PATH from '../UrlPath';
import * as AppContext from '../AppContext';
import * as AppActions from '../AppActions';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const entries = [
  {
    title: '帳戶資訊',
    path: URL_PATH.profile,
  },
  {
    title: '訂單總覽',
    path: URL_PATH.orderList,
  },
];
function ProfileSideNav(props) {
  const {location, style = {}, type = 'vertical'} = props;
  // type = vertical | horizontal
  const {pathname, search} = location;
  const app = React.useContext(AppContext.Context);

  const _logout = React.useCallback(async () => {
    AppActions.setLoading(true);
    await delay(500); // mimic waiting for logout
    app.actions.logout();
    navigate(URL_PATH.landing);
    AppActions.setLoading(false);
  }, [app.actions]);

  if (type === 'horizontal') {
    return (
      <HorizontalWrapper style={style}>
        {entries.map((route, idx) => (
          <div
            key={idx}
            className={`item${
              pathname.indexOf(route.path) !== -1 ? ' active' : ''
            }`}
            onClick={() => navigate(route.path)}>
            <h3>{route.title}</h3>
          </div>
        ))}
        <div className="item" onClick={_logout}>
          <h3>登出</h3>
        </div>
      </HorizontalWrapper>
    );
  }

  return (
    <Wrapper style={style}>
      {entries.map((route, idx) => (
        <div
          key={idx}
          className={`item${
            pathname.indexOf(route.path) !== -1 ? ' active' : ''
          }`}
          onClick={() => navigate(route.path)}>
          <h3>{route.title}</h3>
        </div>
      ))}
      <div className="item" onClick={_logout}>
        <h3>登出</h3>
      </div>
    </Wrapper>
  );
}

const HorizontalWrapper = styled.div`
  display: flex;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  & > .item {
    cursor: pointer;
    padding: 20px;
    & > h3 {
      font-size: 16px;
      text-align: center;
      color: #717171;
    }

    &:hover {
      & > h3 {
        color: #a7a7a7;
      }
    }
  }

  & > .item.active {
    & > h3 {
      font-weight: bold;
      color: var(--theme-primary);
    }
  }
`;

const Wrapper = styled.div`
  & > .item {
    cursor: pointer;
    padding: 20px;
    border-top: 1px solid #cecece;
    & > h3 {
      font-size: 16px;
      text-align: center;
      color: #717171;
    }

    &:hover {
      & > h3 {
        color: #a7a7a7;
      }
    }
  }

  & > .item.active {
    & > h3 {
      font-weight: bold;
      color: var(--theme-primary);
    }
  }
`;

export default ProfileSideNav;
