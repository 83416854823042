import React from 'react';
import styled from 'styled-components';
import ProfileSideNav from '../../components/ProfileSideNav';
import queryParams from '../../utils/queryParams';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {message} from 'antd';
import {format} from '../../utils/date';
import {
  LOGISTICE_TYPE_DISPLAY,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS,
  PAYMENT_STATUS_DISPLAY,
} from '../../domain/Constants';
import {navigate} from 'gatsby-link';
import URL_PATH from '../../UrlPath';
import useDimension from '../../hooks/use-dimension';

function OrderListPage(props) {
  const {
    location: {search},
  } = props;
  const app = React.useContext(AppContext.Context);
  const [orderList, setOrderList] = React.useState([]);
  const {dimension, tablet, mobile} = useDimension();

  React.useEffect(() => {
    const _fetchOrderList = async () => {
      AppActions.setLoading(true);
      try {
        let orders = await app.actions.getOrders();
        setOrderList(orders);
      } catch (err) {
        //
        message.error('您好，目前無法取得訂單資訊！請聯絡客服或稍後再試');
      }
      AppActions.setLoading(false);
    };

    if (app.profile) {
      _fetchOrderList();
    }
  }, [app.profile]);

  return (
    <Wrapper tablet={tablet}>
      <div className="container">
        <div className="sider">
          <ProfileSideNav
            type={tablet ? 'horizontal' : 'vertical'}
            location={props.location}
          />
        </div>
        <div className="content">
          <div className="header">
            <div className="title">單據編號</div>
            <div className="title">付款狀態</div>
            <div className="title">訂單狀態</div>
            <div className="title">運送方式</div>
            <div className="title">總額</div>
            <div className="title">取貨/到貨時間</div>
            <div className="title">訂單成立時間</div>
          </div>
          {orderList.length === 0 && (
            <div className="no-order-placeholder">目前尚無訂單紀錄</div>
          )}
          <div className="data-content">
            {orderList.map((order, idx) => (
              <OrderItem key={idx} order={order} />
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const OrderItem = (props) => {
  const {order} = props;
  return (
    <OrderItemWrapper
      onClick={() => navigate(`${URL_PATH.order}?id=${order.order_id}`)}>
      <div className="cell">{order.order_id}</div>
      <div className="cell">{PAYMENT_STATUS_DISPLAY[order.payment_status]}</div>
      <div className="cell">{ORDER_STATUS_DISPLAY[order.status]}</div>
      <div className="cell">{LOGISTICE_TYPE_DISPLAY[order.logistic_type]}</div>
      <div className="cell">{`NT ${order.total}`}</div>
      <div className="cell">
        {format(order.delivery_date.$date, 'YYYY-MM-DD')}
      </div>
      <div className="cell">{format(order.created, 'YYYY-MM-DD HH:mm')}</div>
    </OrderItemWrapper>
  );
};

const OrderItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 2fr 2fr;
  padding: 10px 16px;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }

  & > .cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Wrapper = styled.div`
  background: #fff;
  flex: 1;
  & > .container {
    max-width: var(--content-max-width);
    padding: 0px var(--content-padding);
    margin: 0 auto;
    display: flex;
    flex: 1;
    width: 100%;
    padding-top: 45px;

    & > .sider {
      width: 200px;
    }

    & > .content {
      flex: 1;
      padding: 0px 15px;

      & > .no-order-placeholder {
        font-size: 14px;
        text-align: center;
        color: #717171;
        padding: 20px 0px;
      }

      & > .header {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr 1fr 1fr 2fr 2fr;
        background-color: #fafafa;
        border-bottom: 1px solid #cecece;
        font-size: 14px;
        color: #595757;
        padding: 10px 16px;

        & > .title {
          font-size: 14px;
          color: #717171;
        }
      }
    }
  }

  ${(props) =>
    props.tablet
      ? `
    & > .container {
        flex-direction: column;
        align-items: center;
        
        & > .sider {
          width: 600px;
          max-width: 100%;
          margin-bottom: 40px;
        }

        & > .content {
          width: 600px;
          max-width: 100%;
          padding: 0px;
        }
      }
  `
      : ''}
`;
export default OrderListPage;
